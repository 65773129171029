<template>
  <div>
    <!--begin::Content header-->

    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin" style="min-width: 350px">
      <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark"
              >Entreprises</span
            >
            <span class="text-muted mt-3 font-weight-bold font-size-sm"
              >Selectionner une entreprise</span
            >
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-8">
          <b-overlay :show="isLoading" rounded="sm">
            <!--begin::Item-->
            <div
              class="d-flex align-items-center mb-10"
              v-for="customer in customers"
              :key="customer.CustomerId"
            >
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-info mr-5">
                <span class="symbol-label">
                  <i class="far fa-building text-info"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <a
                  href="javascript:;"
                  class="text-dark text-hover-primary mb-1 font-size-lg"
                  @click="selectCustomer(customer)"
                  >{{ customer.CompanyName }}</a
                >
              </div>
              <!--end::Text-->
            </div>
          </b-overlay>
        </div>
        <button
          ref="kt_login_signin_submit"
          class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          @click="logout()"
        >
          Se déconnecter
        </button>
        <!--end::Body-->
      </div>
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import CustomerService from "../../../services/customer.service";
import {
  UPDATE_CUSTOMERS,
  SELECT_CUSTOMER,
} from "../../../core/services/store/customer.module";
import { LOGOUT } from "../../../core/services/store/auth.module";

export default {
  name: "customers",
  data() {
    return {
      isLoading: false,
      customers: [],
    };
  },
  mounted: function () {
    this.getCustomers();
  },
  methods: {
    getCustomers() {
      this.isLoading = true;
      CustomerService.getCustomers()
        .then((result) => {
          this.$store.dispatch(UPDATE_CUSTOMERS, result.data);
          this.customers = result.data;
          if (this.customers.length == 1) {
            this.selectCustomer(this.customers[0]);
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    selectCustomer(customer) {
      this.$store.dispatch(SELECT_CUSTOMER, customer);
      this.$router.push({ name: "subscriptions" });
    },
    logout() {
      this.$store.dispatch(LOGOUT);
    },
  },
  computed: {
    ...mapState({
      //errors: (state) => state.auth.errors,
    }),
  },
};
</script>
