import backofficeApiService from "../core/services/backofficeapi.service";

export default {
  getCustomers() {
    return backofficeApiService.get(`subscription/GetWebServiceCustomers/arn`);
  },
  getUserInfo() {
    return backofficeApiService.post(`Account/getprofilinfos`);
  },
  getSubscriptions() {
    return backofficeApiService.get(
      `subscription/GetWebService/arn`
    );
  },
};
